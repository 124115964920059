<template lang="pug">
div
  pull-refresh-list(
    ref="list"
    @update:list="list = $event"
    :getListMethod="getListData"
  )
    van-swipe-cell(v-for="(offer, index) of list" 
      :key="index"
      stop-propagation)
      van-card.van-hairline--bottom(
        v-bind="offer"
        :tag='offer.tag'
        @click="$router.push({name: 'offerdetail', query: {id: offer.id}})"
        )
        template(#title)
        template(#desc)
          van-tag(plain type='danger') 报价单名称:  {{ offer.title}}
          .label 公司：{{offer.company_name}}
          .label 收货人: {{offer.receipt}}
          .label 更新日期: {{offer.update_time | dataFormat}}
      template(#right='')
        van-button(square='' type='danger' text='删除' style="height:100%;" @click="onDelete(offer)" )
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'OfferList',
  props: ['value', 'quote_state', 'search_key', 'refresh'], // value = 客户id   quote_state=报价单状态
  data() {
    return {
      list: [],
      flowStatus: {},
    }
  },
  computed: {
    ...mapGetters(['getFlow']),
    userId() {
      return this.$store.state.userInfo.user_poi_users
    },
    sellerId() {
      return this.$store.state.userInfo.seller_poi_sell_users
    },
    noEdit() {
      return this.no_edit
    }
  },
  watch:{
    quote_state(){
      this.onRefresh()
    },
    refresh(v) {
      if(v != null) {
        this.onRefresh()
      }
    }
  },
  async mounted() {
    let res = await this.getFlow('quote_state')
    this.flowStatus = res.status
  },
  methods: {
    async getListData(params) {
      try {
        let where = {
          log_to: 0,
          // seller_poi_users: this.userId,
          seller_poi_sell_users: this.sellerId,
        }
        if(this.search_key) {
          where.title = {
            $likeboth: {
              concat: 'number',
              keyword: this.search_key
            }
          }
        }
        if(this.quote_state && this.quote_state != 'all')  where.quote_state = this.quote_state
        if(this.value)  where.cus_com_poi_ofc_cus_companys = this.value

        let param = {
          ...params,
          where
        }
      
        let res = await this.$api.GetOffer(param)
        this.list = this.list.concat(res.data.items.map(item=>{
          let thumb = 'null'
          let tag = this.flowStatus[item.quote_state].alias
          if(item.quote_rel_quote_item) {
            if (item.quote_rel_quote_item.length > 0) {
              thumb = item.quote_rel_quote_item[0].thumbnail_url
            }
          }else {
            tag = '无效的'
          }
          return {
            ...item,
            thumb,
            tag,
          }
        }))//.filter(p=> p.quote_rel_quote_item) // 过滤失效文件
        return res.data.items.length
      }catch(ex) {
        this.$toast(this.$error(ex))
      }
    },

    onRefresh() {
      this.list = []
      this.$refs.list.refresh()
    },

    onDelete(offer) {
      if(offer.quote_state == 'unoffer') {
        this.$dialog.confirm({
          title: '',
          message: '确认删除报价单？',
        }).then(async () => {
          try {
              let res = await this.$api.DeleteQuote(offer.id)
              this.$toast('删除成功')
              this.onRefresh()
            } catch(ex) {
              this.$toast(this.$error(ex))
            }
        })
        .catch(() => {})
      } else {
        this.$toast('已报价暂不可删除！')
      }
    }
  }
}
</script>

