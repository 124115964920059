<template lang="pug">
div
  van-cell-group(title="甲方信息")
    van-cell(:is-link="!no_edit" center @click="onSelectClient()")
      template(#title)
        | {{fields_data.company_name}}
      template(#label)
        | {{fields_data.receipt}} {{fields_data.tel}}
        | {{fields_data.address}}
  van-cell-group(title="报价单信息")
    component(v-for="(field, index) of fields"
      :key="index" 
      :is="field.component ? field.component : 'van-field'"
      v-model='fields_data[field.key]' 
      :label='field.label'
      v-bind='field.attrs'
      :disabled='no_edit'
      clearable
      )
  van-cell-group(title="商品信息")
  van-cell-group(v-if='rooms && rooms.length>0' v-for="(c, index) of rooms"
    :key="index"
    :title="c.name || '未分组'"
    )
    van-swipe-cell(v-for='item in c.goods', :key='item.id'  stop-propagation :disabled='no_edit')
      van-card(
        v-if="item.delete === 'no'"        
        :name='item.sku_poi_furniture_sku'
        :title='item.fur_name', 
        :desc= '`${item.color}${item.sizes}${item.version}`', 
        :num='item.num', 
        :price='formatPrice(item.price)', 
        :thumb='`${item.thumbnail_url}${previewSize}`'
        @click-thumb="item.fur_id_poi_furnitures>0 ? $router.push({name:'detail', query: {id: item.fur_id_poi_furnitures, sk_id: item.sku_poi_furniture_sku}}) : () => {$toast('非库存商品无法查看')}"
      )
        template(#num v-if='!no_edit')
          van-stepper(v-model="item.num" integer button-size="26px")
        template(#price v-if='fields_data.is_factory_quote == "y"')
          van-row
            van-col
              span(style='color:red') 销售价： ￥{{item.price}}
          van-row
            van-col
              span(style='color:blue') 出厂价： ￥{{item.factory_price}}  
        template(#price v-else)
          span(style='color:red') 销售价： ￥{{item.price}}
      template(#right='')
       van-button(square='' type='danger' text='删除' style="height:100%;" @click="onDelete(c, item)" )
  van-cell-group(v-if='rooms && rooms.length>0' title="总计")
    van-field(label="数量:", :value="total.count" disabled)
    van-field(label="价格:", :value="total.price" disabled)
  van-action-sheet(v-if='showClient' v-model="showClient" title="选择客户")
    client( :is-component="true" @onSelect="onClientSelect")
  van-action-sheet(v-if='showAddress'  v-model='showAddress', title='选择地址')
    addr-list(@onAddressChange="onAddressChange" :value='fields_data.cus_com_poi_ofc_cus_companys')
  van-submit-bar
    template(#button)
      van-button(v-if='fields_data && fields_data.quote_state != "unoffer" && fields_data.is_factory_quote == "y"' style='height:40px;width:110px;' icon='down' size='normal' round type="primary" color="#1989fa" @click="onDownloadQuote" :disabled='commitState') 工厂报价
      van-button(v-if='fields_data.quote_state != "unoffer"' style='height:40px;width:110px;' size='normal' round type="warning"  @click="onQuoteLog") 查看历史
      van-button(v-if='!no_edit && (fields_data.quote_state == "unoffer" || fields_data.quote_state == "offering")' style='height:40px;width:110px;' size='normal' round color="linear-gradient(to right, #ff6032, #ee0a24)"  @click="onSubmit" :disabled='commitState') {{fields_data.quote_state == 'unoffer' ? '提交报价单' : '更新报价单'}}
</template>

<script>

import AddressList from '../../views/hall/_AddressList'
import Client from '../../views/hall/client'
import {previewSize} from '../../lib/helpers/OssImageHelper'
import fileSaver  from'file-saver'
import exceljs from '../../lib/excel/export'
import quote_config from '../../lib/excel/quoteconfig'
const template_url = '/template/quote.xlsx'

export default {
  name: 'QuoteInfo',
  props: ['value', 'no_edit'],
  components: {
    'addr-list':AddressList,
    Client
  },
  data() {
    return {
      previewSize,
      commitState: false,
      fields: [
        { 
          key: 'title', 
          label: '报价单名称',
          attrs: {
            required: true,
            placeholder: "请输入报价单名称",
            rules: [{required: true, message: '请输入报价单名称'}]
          }
        },
      ],
      fields_data: {
        title: '',
        code: '',
        cycle_time: 0,
        delivery_time: 0,
        paymode: '',
        payment: '',
        has_bill: '',
        remark: '',
      },
      showClient: false,
      showAddress: false,
      rooms: []
    }
  },
  watch: {
    value(newV) {
      this.init(newV)
    },
    no_edit(x) {
      this.noEdit = x
    }
  },
  computed: {
    total() {
      let count = 0 
      let price = 0
      this.rooms.forEach( room => {
          room.goods.forEach( good => {
            count += parseInt(good.num)
            price += good.price * good.num
          })
      }); 
      price = this.formatPrice(price)
      return {
        count: `${count}  件`,
        price: `￥${price}`,
      }
    },
    noEdit:{
      get() {
      return this.no_edit
      },
      set(v){}
    }
  },
  methods: {
    onSelectClient() {
      if(!this.no_edit) {
        this.showClient = true
      }
    },

    init(obj) {
      if(obj) {
        this.fields_data = {...obj}
        this.rooms = this.quoteParse(obj.quote_rel_quote_item)
      }
    },

    formatPrice(price) {
      return (price/1).toFixed(2);
    },

    // 数据组装
    quoteParse(quoteItems) {
      if(quoteItems && quoteItems.length > 0) { 
        let order = 0
        let groups = []
        quoteItems.map(({ group, project })=> {
          if (groups.findIndex(p=> p.id == group) == -1) {
            let groupItem = {
              id: group,
              name: project,
              num: 0,
              total: 0,
              order: order++,
              delete: 'no'
            }
            groups.push(groupItem)
          }
        })
        groups.forEach((item)=>{
          item.goods = quoteItems
            .filter(p=>p.group == item.id)
            .sort((g1, g2) => g1.order - g2.order)
            .map((g) => {
              g.delete = 'no'
              return g
            })
        })
        return groups
      }
      return []
    },

    onClientSelect(client) {
      this.fields_data.cus_com_poi_ofc_cus_companys = client.id
      this.fields_data.company_name = client.fullname || client.spname
      this.fields_data.receipt = ''
      this.fields_data.tel = ''
      this.fields_data.address = ''
  
      this.showClient = false
      this.showAddress = true
    },

    onAddressChange({name, tel, address}) {
      this.fields_data.receipt = name
      this.fields_data.tel = tel
      this.fields_data.address = address
      this.showAddress = false
    },

    onDelete(array, obj) {
      this.$dialog.confirm({
        title: '',
        message: '是否删除商品？',
      }).then(async () => {
        try {
          if(obj.id > 0) {
            obj.delete = 'yes' 
            // 暂时不知得什么原因  需要重新处理数据才可以刷新页面
            array.goods = array.goods.filter(p => p!= obj)
            array.goods.push(obj) 
          } else {
            array.goods = array.goods.filter(p => p!= obj)
          }
        } catch(ex) {
          this.$toast(this.$error(ex))
        }
      })
      .catch(() => {})
    },
    
    // 获取保存的数据
    getData() {
      let data = {...this.fields_data}
      data.quote_rel_quote_item = this.formatQuoteGoods()
      let temp_factory_discount_price = data.quote_rel_quote_item.reduce((pre,cur) => {
        return pre + parseFloat(cur.factory_price) * parseFloat(cur.num)
      },0).toFixed(2)
      let factory_favor_amount = data.factory_favor_amount ?  parseFloat(data.factory_favor_amount) : 0
      let cost = data.cost ?  parseFloat(data.cost) : 0
      data.factory_discount_price = temp_factory_discount_price - factory_favor_amount + cost
      data.factory_total_price = data.factory_discount_price 
      return data
    },

    // 格式化所需提交的商品数据
    formatQuoteGoods() {
      let garr = this.rooms.reduce((result, sub) => {
        result = result.concat(sub.goods.map((item) => {
          return {
            ...item,
          }
        }))
        return result
      }, [])
      return garr
    },

    // 查看历史
    onQuoteLog() {
      this.$router.push({
        name: 'offerlog',
        query: {
          id: this.fields_data.id
        }
      })
    },

    // 提交报价单
    async onSubmit() {
      if(!this.fields_data.company_name) {
        this.$toast('请选择甲方客户')
        return
      }
      if(!this.fields_data.receipt) {
        this.$toast('请选择客户联系地址')
        return
      }
      if(!this.fields_data.title) {
        this.$toast('请填写报价单名称')
        return
      }
      this.$dialog.confirm({
        title: '',
        message: '确认提交',
      }).then(async () => {
        this.commitState = true
        try {
          let data = this.getData()
          data.is_factory_quote = 'n'
          await this.$api.UpdateQuote(data)
          this.$toast("提交成功")
          this.$router.go(-1)
        } catch (e) {
          this.$toast(this.$error(e))
        }
        this.commitState = false
      }).catch(() => {})
    },

    // 下载工厂报价
    async onDownloadQuote() {
      let data = {
        data: {
          ...this.fields_data,
          items: this.fields_data.quote_rel_quote_item.map((good) => {
            let price = good.factory_price || 0
            let num = good.num || 1
            return {
              ...good,
              price,
              total_price: num * price
            }
          }),
        },
        template_url: template_url,
        excel_config: quote_config
      }
      let total = data.data.items.reduce((total,cur)=> {
        return total + cur.total_price
      },0)
      data.data.total_price = total
      let buffer = await exceljs.writeFile(data)
      var blob = new Blob([buffer])
      let filename = `${this.fields_data.title}-${Date.now()}.xlsx`
      fileSaver.saveAs(blob, filename);
    }
  },
  mounted() {
    this.init(this.value)
  }
}
</script>