<template lang="pug">
div
  van-field(:label="label" clickable :disabled="disabled" readonly v-bind='$attrs'
     @click="onClick"
     )
    template(#input)
      van-cell(style='margin-top:-10px')
        template(#title)
          span {{address.address}}
        template(#label)
          | {{address.name}} {{address.tel}}
  van-action-sheet(v-if='show'  v-model='show', title='选择地址')
    addr-list(@onAddressChange="onAddressChange")
</template>

<script>
import AddressList from '../../views/hall/_AddressList'

// 地址选择器
export default {
  name: 'PickerAddressSelect',
  props: ['value', 'label'],
  components: {
    'addr-list':AddressList,
  },
  data() {
    return {
      show: false,
      v: {
        label: '',
        value: ''
      },
      address: {
        
      }
    }
  },
  computed: {
    disabled() {
      return this.$attrs.disabled
    },
    columns() {
      return []
    },
  },
  watch: {
    value(newV) {
      this.v = this.value ? this.value : ''
    }
  },
  methods: {
    onAddressChange(obj) {
      this.address = obj
      this.$emit('input', obj)
      this.show = false
    },

    onClick() {
      if (!this.disabled) {
        this.show = true
      }
    }
  },
  mounted() {
  
  }
}
</script>