<template lang="pug">
div
  van-address-list(v-model='chosenAddressId', 
    :list='list', 
    default-tag-text='默认', 
    @add='onAdd', 
    @edit='onEdit')
  van-action-sheet(v-model='showEdit', title='编辑联系地址')
    van-address-edit(:area-list='areaList',
      :address-info= 'editingAddress'
      :show-delete= 'isEdit' 
      show-set-default=''
      :area-columns-placeholder="['请选择', '请选择', '请选择']"
      @save='onSave'
      @delete='onDelete' 
      )
  loading-view(:value="loadingState")
</template>

<script>
import {
  vantToDpProvince, 
  vantToDpCity, 
  vantToDpDistrict,
  getVantAddress
} from '../../lib/helpers/AreaHelper'
import areaList from '../../lib/arealist'

let promise = null
let deliveryPromise = null
export default {
  props: ['value'],
  data() {
    return {
      loadingState: false,
      chosenAddressId: '1',
      editingAddress: {},
      showEdit: false,
      isEdit: false,
      areaList,
      list: [
        // {
        //   id: '1',
        //   name: '张三',
        //   tel: '13000000000',
        //   address: '浙江省杭州市西湖区文三路 138 号东方通信大厦 7 楼 501 室',
        //   province: '',       // 省份
        //   city: '',           // 城市
        //   county: '',         // 区县
        //   addressDetail: '',  // 详细地址
        //   areaCode: '',   // 地区编码，通过省市区选择获取（必填）
        //   postalCode: '', // 邮编
        //   isDefault: false  
        // },
      ],
      deliveryTypes: []
    }
  },
  watch: {
    chosenAddressId(newId) {
      let address = this.list.find((item) => {
        return item.id === newId
      })
      this.$emit('onAddressChange', address)
    },
    value(newW){
    }
  },
  computed: {
    user() {
      return this.$store.state.userInfo
    },
  },
  methods: {

    async getDeliveryTypes() {
      try {
        if (!deliveryPromise) {
          let params = {
            where: {
              com_id_poi_companys: this.$store.state.routeInfo.comId,
            }
          }
          deliveryPromise = this.$api.GetDeliveryAddress(params)
        }
        const res = await deliveryPromise
        this.deliveryTypes = res.data.items
      } catch (error) {
        console.log(error)
      }
    },

    async getListData() {
      this.loadingState = true
      if (!promise) {
        promise = this.$api.GetUserAddress()
      }
      let res = await promise
      // 公司发货
      let company_delivery_type = this.deliveryTypes.find(p => p.need_take == '0' && p.need_lms_quote == '1' && (p.name.indexOf('工厂发货') != -1 || p.name.indexOf('工厂提货') != -1))

      this.list = res.data.items.map((item) => {
        return {
          ...item,
          name: item.receipt,
          tel: item.tel,
          address: item.full_address,
          isDefault: item.is_default  == '0' ? false : true,
          province: item.province_poi_province,        
          city: item.city_poi_city,        
          county: item.district_poi_district,      
          addressDetail: item.detail_address,
          areaCode: '',   
          postalCode: '', 
          id_poi_scm_delivery_type: company_delivery_type ? company_delivery_type.id : ''
        }
      })
      // 业务自提
      let self_delivery_type = this.deliveryTypes.find(p => p.need_take == '1' && p.need_lms_quote == '0' && (p.name.indexOf('工厂自提') != -1 || p.name.indexOf('自提') != -1))
      this.list.unshift({
        name: this.user.name,
        tel: this.user.tel,
        receipt: this.user.name,
        address: '工厂自提',
        self: true,
        id_poi_scm_delivery_type: self_delivery_type ? self_delivery_type.id : ''
      });
      this.loadingState = false
    },
    onAdd() {
      this.editingAddress = { id: this.list.length }
      this.isEdit = false
      this.showEdit = true
    },
    onEdit(item) {
      this.initAreaCode(item)
      this.isEdit = true;
      this.showEdit = true;
      this.editingAddress = item;
    },
    async onSave(info) {
      let {province, city, county} = info
      let province_poi_province = (await vantToDpProvince(this, province)).id
      let city_poi_city = (await vantToDpCity(this, city, province_poi_province)).id
      let district_poi_district = (await vantToDpDistrict(this,county, city_poi_city)).id
      let full_address = `${province}${city}${county}${info.addressDetail}`
      info.address = full_address

      this.showEdit = false
      let param = {
          receipt: info.name,
          tel: info.tel,
          province_poi_province,
          city_poi_city,
          district_poi_district,
          full_address: full_address,
          seller_poi_users: this.user.user_poi_users, 
          detail_address: info.addressDetail, 
          is_default: info.isDefault ? 1 : 0
      }
      // put 
      if (this.isEdit) {
        param.id = info.id
        try {
          await this.$api.PutUserAddress(param)
          promise = null
          if (param.is_default == 1) {
            let findOldDefaultAddress = this.list.find(p => p.isDefault && p.id != param.id)
            if (findOldDefaultAddress) {
              await this.refreshOldDetaultAddress(findOldDefaultAddress)
            }
          }
          this.$toast('修改成功')
        } catch (e) {
          this.$toast(this.$error(e))
        }
        this.list = this.list.map((item) =>
          item.id === info.id ? info : item
        )
      } else { // post 
        param.type = 'new'
        try {
          let res = await this.$api.PostUserAddress(param)
          if (param.is_default == 1) {
            let findOldDefaultAddress = this.list.find(p => p.isDefault)
            if (findOldDefaultAddress) {
              await this.refreshOldDetaultAddress(findOldDefaultAddress)
            }
          }
          promise = null
          this.$toast('添加成功',res)
          info.id = res.data.id
          this.list.push({...info})
        } catch (e) {
          this.$toast(this.$error(e))
        }
      }
      this.chosenAddressId = info.id
    },

    async onDelete(info) {
      this.showEdit = false
      let param = { id: info.id }
      try {
        await this.$api.DeleteUserAddress(param)
        this.$toast('删除成功')
        this.list = this.list.filter((item) => item.id !== info.id)
        promise = null
      } catch (e) {
        this.$toast(this.$error(e))
      }
      if (this.chosenAddressId === info.id) {
        this.chosenAddressId = null
      }
    },

    async initAreaCode(info){
      let province = await this.$api.GetProvince({id: info.province})
      let city = await this.$api.GetCity({id: info.city})
      let county = await this.$api.GetDistrict({id: info.county})
      let code = getVantAddress(province.data.ProvinceName, city.data.CityName, county.data.DistrictName)[2].code
      this.$set(this.editingAddress,'areaCode',code)
    },

    // 更新旧的默认地址
    async refreshOldDetaultAddress(obj) {
      const data = {
        id: obj.id,
        is_default: 0
      }
      await this.$api.PutUserAddress(data)
      obj.isDefault = false
      obj.is_default = 0
    }
  },
  async created() {
    await this.getDeliveryTypes()
    this.getListData()
  }
}
</script>