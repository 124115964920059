<template lang="pug">
div
  van-field(:label="label" :value="stringedValue" clickable @click="show = true" readonly)
  van-popup(v-model="show" position="bottom")
    van-area(
      :area-list="arealist"
      :value="code"
      @confirm="onConfirm"
      @cancel="show = false"
    )
</template>

<script>
import arealist from '../../lib/arealist'
export default {
  name: 'AreaField',
  props: ['value', 'label'],
  data() {
    return {
      arealist,
      show: false,
      v: [],
    }
  },
  computed: {
    stringedValue() {
      let [province, city, district] = this.value
      if (!province || !city || !district) {
        return '北京市/北京市/东城区'
      } else {
        return `${province.name}/${city.name}/${district.name}`
      }
    },
    code() {
      if(this.v && this.v.length==3){
          return this.v[2].code
      }else{
        return '110000'
      }
    }
  },
  watch: {
    value(newV) {
      this.v = newV
    },
  },
  methods: {
    onConfirm(value) {
      this.$emit('input', value)
      this.show = false
    },
  },
  mounted() {
    this.v = this.value
  }
}
</script>