import axios from 'axios'
import Excel from 'exceljs'
import moment from 'moment'

/**
   * 导出excel文件
   * @param template_url 模板excel的地址
   * @param data  数据
   * {
   *    id:0,
   *    items: []
   * }
   * @param excel_config  模板配置 {theader:[], tbody:[], tfooter:[], tother:{}}
   * 
*/
const writeFile =  async ( {data, template_url, excel_config})=> {
    try {

        let res = await axios({
            method: 'get',
            responseType: 'blob',
            url: `${window.location.origin}${template_url}`
        })
        let type = res.headers['content-type']
        let blob = new Blob([res.data], {type: type})
        // 获取模板
        let workbook = await readWookbook(blob)

        let sheet = workbook.getWorksheet(1);

        let { theader, tbody_header : t_body, tfoot, tother} = excel_config
        if(data.items && data.items.length >0) {
            // 初始化模板（添加行）
            initTable(sheet, data, excel_config)
        }
    
        // 写 header部分 
        setHeader(sheet, data, theader)
        
        // 获取bodyimg和每一行其中所有单元格值最长的字符串长度 length
        let { images:bodyImgs, maxValueLength: rowMaxValueLength} = setBody(sheet, data, t_body, tother)
        
        for (let i = 0; i < rowMaxValueLength.length; i++) {
            // 单元格最大内容的 行号 列号 文本长度 文本size
            let { col, row, length, font_size } = rowMaxValueLength[i]
            let colWidth = (sheet.getColumn(col).width || sheet.properties.defaultColWidth) || 25
            // -- console.log(col,row,colWidth,length, font_size)
            // 字符串长度 * 2  1个中文字符占两个宽度 目前按14号字体处理
            // 左右预留3个字符的宽度  所有要-6
            // colWidth - 6 = 一行总共需要多少个占位符
            let row1 = sheet.getRow(row)
            let textRow = length * 2 / (colWidth - 8)
            let defaultHeight = row1.height 
            let newHeight = sheet.properties.defaultRowHeight * textRow
            row1.height = Math.max(defaultHeight,newHeight) 
        }
        
        // 处理单元格的图片
        for (let i = 0; i < bodyImgs.length; i++) {
            let {url, col, row } = bodyImgs[i]
            await addImageOnSheet(workbook, sheet, row, col, url)
        }
        
        // 设置footer
        setFooter(sheet, data, tfoot, tother)
        
        let result = await workbook.xlsx.writeBuffer()

        return result
        
    }catch(e) {
        console.log('excel export error:', e)
    }
    // px=13.5/72*96 =18.00
};
  
const createImage = async (url) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url
    image.onload = () => {
      resolve(image)
    }
    image.onerror = (e) => {
      reject(e)
    }
  })
};

// 图片在单元格内的缩放系数 1为挤满单元格
const imageScale = 0.8

// 指定sheet中单元格添加图片
const addImageOnSheet = async (workbook, sheet, row, col, url, userOriginImageSize = false) => {
  // 获取列宽
  let colWidth = sheet.getColumn(col).width
  // 获取行高
  let rowHeight = sheet.getRow(row).height
  // 列宽像素值
  let colPX = colWidth * 7
  // 行高像素值
  let rowPX = rowHeight / 72 * 96               // 镑转像素计算公式 = *96/72

  // 是否使用原始图片尺寸
  if (!userOriginImageSize) {
    let imgBuffer = await axios.get(`${url}?x-oss-process=image/resize,h_${512},w_${512},m_pad/format,jpg`,{responseType: 'arraybuffer'})
    let finalWidth = Math.floor(Math.min(colPX, rowPX))
    let imageId = workbook.addImage({
      buffer:  imgBuffer.data,
      extension: 'jpeg'
    })
    sheet.addImage(imageId, {
      tl: {row: row-0.5,col: col-0.5},
      ext: {width: finalWidth * imageScale, height: finalWidth * imageScale} ,
    })
  } else {
    // 设置到表格中的图片宽高
    let imgW = colPX
    let imgH = rowPX

    // 创建图片用于获取宽高
    let tempImg = await createImage(url)
    // 真实图片的宽高比
    let orignWidth = tempImg.width
    let orignHeight = tempImg.height
  

    // 列宽像素大于行高像素
    // ---------
    // ---------
    if (colPX >= rowPX) {
      imgH = rowPX
      imgW = rowPX / orignHeight * orignWidth
      if (imgW > colPX) {
        imgW = colPX
        imgH = colPX / orignWidth * orignHeight
      }
    }
    // 行高大于列宽
    // --
    // --
    // --
    if (colPX < rowPX) {
      imgW = colPX
      imgH = colPX / orignWidth * orignHeight
      if (imgH > rowPX) {
        imgH = rowPX
        imgW = rowPX / orignHeight * orignWidth
      }
    }
    
    let imgBuffer = await axios.get(`${url}?/format,jpg`,{responseType: 'arraybuffer'})
    let imageId = workbook.addImage({
        buffer:  imgBuffer.data,
        extension: 'jpeg'
    })
    sheet.addImage(imageId, {
      tl: {row: row-0.5,col: col-0.5},
      ext: {width: imgW * imageScale, height: imgH * imageScale} ,
    })
    tempImg = null
  }
};


// 通过blob获取excel文件
const readWookbook = async (blob) => {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = async (e) => {
            var data = e.target.result
            try {
                let workbook = new Excel.Workbook()
                await workbook.xlsx.load(data)
                resolve(workbook)
            }catch(ex) {
                reject(ex)
            }
        }
        reader.onerror = (e) => {
            reject(e)
        }
        reader.readAsArrayBuffer(blob);
    })
};

// 处理表格，新增行用于填充数据，移动表尾
const initTable = (sheet, data, excel_config) => {
    let { tother: { body_header_row, foot_start_row, foot_row_count} } = excel_config
    let bodyItems = data.items
    if (bodyItems.length <= 1) {
        return 
    }
    let merges = sheet._merges
    let cellPropertys = {}
    // 第一步要移动表尾, 从最后一行开始移动
    for (let i = foot_row_count - 1; i >= 0; i--) {
        let moveRow = i + foot_start_row
        let propertys = []
        let row = sheet.getRow(moveRow)
        let rowCells = row.model.cells
        // 缓存复制之前的单元格样式
        for (let j = 0; j < rowCells.length; j++) {
            let ads = rowCells[j].address
            let cell = sheet.getCell(ads)
            propertys.push(getCellProperty(cell, merges))
            // 清理复制之前的单元格信息
            cell.dataValidation = null
        }
        cellPropertys[moveRow] = propertys
    }

    // 已body起始行为复制 添加bodyitems.length行
    sheet.duplicateRow(body_header_row + 1, bodyItems.length - 1, true);

    // 移动后的单元格merge数据 移动后的行
    let moveMergeData = []
    // 设置移动后的单元格属性
    for (let i = foot_row_count - 1; i >= 0; i--) {
        let moveRow = i + foot_start_row
        let newRowNumber  = moveRow + (bodyItems.length - 1)
        let newRow = sheet.getRow(newRowNumber)
        let newCells = newRow.model.cells
        let cellProperty = cellPropertys[moveRow]
        for (let j = 0; j < cellProperty.length; j++) {
            let ads = newCells[j].address
            let cell = sheet.getCell(ads)
            setCellProperty(cell, cellProperty[j])
            if(cellProperty[j].merge) {
                moveMergeData.push({
                    row: cell.row,      // 移动后的行number
                    merge: cellProperty[j].merge  // 移动前的合并信息
                })
            }
        }
    }

    // 设置单元格合并
    for (let i = 0; i < moveMergeData.length; i++) {
        let {row, merge:[startRow,startCol,endRow,endCol]} = moveMergeData[i]
        // 按开始行，开始列，结束行，结束列合并（相当于 K10:M12）
        sheet.mergeCells(startRow + (bodyItems.length - 1),startCol,endRow + (bodyItems.length-1),endCol);
    }
};
  
// 设置 header 数据 
const setHeader = (sheet, data, header_config)=> {
    for(let i = 0; i< header_config.length; i++) {
        let key = header_config[i].key
        let value = data[key]
        let {row,col} = header_config[i]
        let cell = sheet.getCell(row,col);
        if(header_config[i].type == 'date') {
            cell.value = moment(value).format('YYYY-MM-DD')
          }
          else {
            cell.value =  value
        } 
    }
};

// 设置 body 数据, 并返回有图片的单元格 {address, url}
const setBody = (sheet, data, body_config, tother) => {
  let { body_header_row } = tother
  body_header_row += 1
  let images = []
  let maxValueLength = []
  let bodyItems = data.items || []
  for(let i = 0; i< bodyItems.length; i++) {
    for (let j = 0; j<body_config.length; j++){
      let key = body_config[j].key
      let colIndex = body_config[j].col
      let cell = sheet.getCell(body_header_row + i,colIndex)
      if(j == 0) {
        cell.value = (i + 1)
      }
      else {
        if(body_config[j].type == 'image') {
          images.push( {
            address: cell.address,
            url: bodyItems[i][key],
            row: cell.row,
            col: cell.col
          })
        }else {
          if(body_config[j].type == 'date') {
            cell.value = moment(bodyItems[i][key]).format('YYYY-MM-DD')
          }
          else {
            cell.value = bodyItems[i][key] ? String(bodyItems[i][key]).trim() : bodyItems[i][key]
          } 
        }
      }
    }
    let valuesLength =   sheet.getRow(body_header_row + i).model.cells.map((p,index)=>{
      return { 
        length: (p.value || '').length || 0,
        col: index + 1,
        font_size: p.style.font.size
      }
    })
    let max = Math.max(...(valuesLength.map(p=>{ return p.length })))
    let maxObj = valuesLength.find(p=>p.length == max)
    maxValueLength.push({
      row: body_header_row + i,
      ...maxObj
    })
  }
  return { images, maxValueLength }
};
  
// 设置 footer 数据
const setFooter = (sheet, data, footer_config, tother) => {
    let { foot_start_row } = tother
    let bodyItems = data.items || []
    for(let i = 0; i< footer_config.length; i++) {
        let key = footer_config[i].key
        let value = data[key]
        let {row,col} = footer_config[i]
        let newR = row + (bodyItems.length - 1)
        let cell = sheet.getCell(newR,col);
        if(footer_config[i].type == 'date') {
            cell.value = moment(value).format('YYYY-MM-DD')
        } else {
            cell.value =  value
        } 
    }
};
  
// 设置单元格属性
const setCellProperty = (cell, property)=>{
    let {model,name,dataValidation,value,note,style} = property
    // cell.model = model
    // cell.name = name
    cell.dataValidation = dataValidation
    // cell.value = value
    // cell.note = note
    cell.style = style
};

// 获取单元格属性
const getCellProperty = (cell, sheetMerges) => {
    let merge = null
    if(sheetMerges[cell.address] && sheetMerges[cell.address].model) {
    // if(cell.isMerged) {
        let {top, left, bottom, right} = sheetMerges[cell.address].model
        merge = [top,left,bottom,right]
    }

    let { model, name, dataValidation, value, note, style } = cell   
    let result = {
        row:cell.row,    
        model,    
        name,    
        dataValidation,    
        value,    
        note,    
        style,
        merge
    }

   ; return result
}


export default { //很关键
    writeFile
}