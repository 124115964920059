<template lang="pug">
div
  van-field(:label="label" :value="v.length + '个联系人'" clickable readonly @click="show = true")
  van-popup(v-model="show" position="bottom")
    van-contact-list(
      v-model="chosenContactId"
      :list="v"
      @add="onAdd"
      @edit="onEdit"
      @select="onSelect"
    )
  van-popup(v-model="showEdit" position="bottom")
    van-form(@submit="onSave")
      van-field(label="姓名" v-model="editingContact.name")
      van-field(label="电话" v-model="editingContact.tel")
      van-field(label="职务" v-model="editingContact.job")
      van-button(type="danger") 保存
      van-button(@click="onDelete") 删除
</template>

<script>
export default {
  name: 'ContactField',
  props: ['value', 'label'],
  data() {
    return {
      show: false,
      chosenContactId: 0,
      v: [],
      isEdit: false,
      showEdit: false,
      editingContact: {}
    }
  },
  watch: {
    value(newV) {
      this.v = newV
    }
  },
  methods: {
    onAdd() {
      this.editingContact = { id: 0, name: '', tel: '', job: '', delete: 'no' }
      this.isEdit = false
      this.showEdit = true
    },
    onEdit(item) {
      this.isEdit = true
      this.showEdit = true
      this.editingContact = item
    },
    onSelect() {
      this.show = false
    },
    onSave() {
      this.showEdit = false

      if (this.isEdit) {
        this.v = this.v.map((item) =>
          item.id === this.editingContact.id ? this.editingContact : item
        );
      } else {
        this.v.push(this.editingContact)
      }
      this.chosenContactId = this.editingContact.id
      this.$emit('input', this.v)
    },
    onDelete() {
      this.showEdit = false
      this.v = this.v.filter((item) => item.id !== this.editingContact.id)
      if (this.chosenContactId === this.editingContact.id) {
        this.chosenContactId = null
      }
    }
  },
  mounted() {
    this.v = this.value
    for (let i in this.constant) {
      if (this.value === i) {
        this.v = {
          label: this.constant[i],
          value: this.value
        }
      }
    }
  }
}
</script>