<template lang="pug">
div
  van-field(:label="label" :value="v.label" clickable 
    :disabled="disabled"
    readonly
    v-bind='$attrs'
     @click="onClick")
  van-popup(v-model="show" position="bottom")
    van-picker(
      show-toolbar
      :columns="columns"
      @confirm="onConfirm"
      @cancel="show = false"
    )
</template>

<script>
export default {
  name: 'PickerFieldCommon',
  props: ['value', 'options', 'label'], // div_constant 自定义可选对像 
  data() {
    return {
      show: false,
      v: {
        label: '',
        value: ''
      }
    }
  },
  computed: {
    disabled() {
      return this.$attrs.disabled
    },
    columns() {
      return this.options.map(({label}) => label)
    },
  },
  watch: {
    value(newV) {
      const findItem = this.options.find(p => p.value == newV)
      if (findItem) {
        this.v = {
          ...findItem
        }
      }
    }
  },
  methods: {
    onConfirm(label) {
      const findItem = this.options.find(p => p.label == label)
      let value = findItem ? findItem.value : ''
      this.v.label = label
      this.$emit('input', value)
      this.show = false
    },

    onClick() {
      if (!this.disabled) {
        this.show = true
      }
    }
  },
  mounted() {
    // 默认选中第一个
    for (let i in this.constant) {
      // if(!this.value) {
      //   this.v = {
      //     label: this.constant[i],
      //     value: i,
      //   }
      //   return 
      // }
      if (this.value === i) {
        this.v = {
          label: this.constant[i],
          value: this.value
        }
      }
    }
  
  }
}
</script>