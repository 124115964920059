const vantToDpProvince = async (vm, name) => {
    let province = (await vm.$api.GetProvince({
        where: {
            ProvinceName: name
        },
        needcount: 0
    })).data
    if (province.length > 0) {
        return province[0]
    } else {
        return 0
    }
}

const vantToDpCity = async (vm, name, proId) => {
    let city = (await vm.$api.GetCity({
        where: {
            CityName: name,
            ProvinceID: proId
        },
        needcount: 0
    })).data
    if (city.length > 0) {
        return city[0]
    } else {
        return 0
    }
}  

const vantToDpDistrict = async (vm, name, cityId) => {
    let newName = name.substring(0,name.length-1)
    let district = (await vm.$api.GetDistrict({
        where: {
            DistrictName: {
                $likeafter: newName
            },
            CityID: cityId
        },
        needcount: 0
    })).data
    if (district.length > 0) {
        return district[0]
    } else {
        return 0
    }
}  

import areaList from '../../lib/arealist'
const dpToVantProvince = (name) => {
  return dpToVantAddress('province_list',name)
}

const dpToVantCity = (name) => {
    return dpToVantAddress('city_list',name)
}  

const dpToVantDistrict = (name) => {
    return dpToVantAddress('county_list',name)
}  

const dpToVantAddress =(typeName,valueName) => {
    let list = areaList[typeName]
    let reuslt = []
    for(var key in list)
        if(valueName.includes(list[key])){
        reuslt.push({
            code: key,
            name: list[key],
            num: key.substring(0,2)
        })
    }
    return reuslt
}

// 通过dpjia address 数据（名称）获取vant 的 完整地址 
// return [0,1,2]
const getVantAddress = (provinceName, cityName, countyName) =>{
    try{
        let pro = dpToVantProvince(provinceName)[0]
        let city = dpToVantCity(cityName).find(({num})=> num===pro.num)
        let dis = dpToVantDistrict(countyName).find(({num})=> num===pro.num)
        if(!pro || !city || !dis)
            throw `${pro}${city}${dis}+${provinceName}-${cityName}-${countyName}`
        return [pro, city, dis]
    }catch(ex) {
        // 暂时返回一个默认值
        return [
            { code: '110000', name: '北京市', },
            { code: '110100', name: '北京市', },
            { code: '110101', name: '东城区', },
            { message: `请求失败:${provinceName}${cityName}${countyName}` },
        ]
    }
}

export {
    vantToDpProvince,
    vantToDpCity,
    vantToDpDistrict,
    dpToVantProvince,
    dpToVantCity,
    dpToVantDistrict,
    getVantAddress
}