<template lang="pug">
div
  van-field(:label="label")
    template(#input)
      van-rate(v-model="v")
</template>

<script>
export default {
  name: 'RateField',
  props: ['value', 'label'],
  data() {
    return {
      v: 0
    }
  },
  watch: {
    value(newV) {
      this.v = newV
    },
    v(newV) {
      this.$emit('input', newV)
    }
  },
  mounted() {
    this.v = this.value
  }
}
</script>