const excel_config = {
  theader: [
    {
      key: 'title',
      type: 'text',
      row: 1,
      col: 1,
      remark: '报价单名称' 
    }
  ],
  tbody_header: [
    {
      key: 'index',
      type: 'number',
      row: 2,
      col: 1,
      remark: '序号' 
    },
    {
      key: 'thumbnail_url',
      type: 'image',
      row: 2,
      col: 3,
      remark: '图' 
    },
    {
      key: 'fur_name',
      type: 'text',
      row: 2,
      col: 2,
      remark: '名称' 
    },
    {
      key: 'code',
      type: 'text',
      row: 2,
      col: 4,
      remark: '型号' 
    },
    {
      key: 'sizes',
      type: 'text',
      row: 2,
      col: 5,
      remark: '规格' 
    },
    {
      key: 'material',
      type: 'text',
      row: 2,
      col: 6,
      remark: '材质' 
    },
    {
      key: 'color',
      type: 'text',
      row: 2,
      col: 8,
      remark: '颜色' 
    },
    {
      key: 'num',
      type: 'number',
      row: 2,
      col: 9,
      remark: '数量' 
    },
    {
      key: 'unit',
      type: 'text',
      row: 2,
      col: 10,
      remark: '单位' 
    },
    {
      key: 'price',
      type: 'float',
      row: 2,
      col: 11,
      remark: '单价' 
    },
    {
      key: 'total_price',
      type: 'float',
      row: 2,
      col: 12,
      remark: '金额' 
    },
    {
      key: 'technology',
      type: 'text',
      row: 2,
      col: 6,
      remark: '工艺' 
    },
    {
      key: 'remark',
      type: 'text',
      row: 2,
      col: 13,
      remark: '备注' 
    }
  ],
  tfoot: [
    {
      key: 'total_price',
      type: 'text',
      row: 4,
      col: 12,
      remark: '合计' 
    }
  ],
  tother: {
    body_header_row: 2,   // body 表头行
    foot_start_row: 4,   // 表尾 开始的行 
    foot_row_count: 2,   // 表尾总行数    
  }
}    

export default excel_config
