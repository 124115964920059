<template lang="pug">
div
  van-field(:label="label" :value="labelValue" clickable readonly @click="isedit ? show=true : ()=>{} " v-bind='$attrs')
  van-popup(v-model="show" round  position="bottom") 
    van-checkbox-group(v-model='result')
      van-cell-group
        van-cell(v-for='(item, index) in list', clickable='', :key='index', :title='`${item}`', @click='toggle(index)')
          template(#right-icon='')
            van-checkbox(:name='item', ref='checkboxes' )
    van-button(type="danger" block round @click="onSubmit($event)") 保存
</template>

<script>
export default {
  name: 'PickerSelect',
  props: ['label', 'value', 'list', 'isedit', 'callback'],
  data() {
    return {
      show : false,
      result: [],
      labelValue: "",
    }
  },
  watch: {
    value(newV) {
      this.initValue(newV)
    },
  },
  methods: {
    initValue(newV) {
      if(newV) {
        this.labelValue = newV
        this.result = newV.split(',')
      }
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
     },
    onSubmit(e) {
      e.stopPropagation()
      // this.labelValue = this.result.join(',')
      if (this.callback) {
        this.callback(this.result.join(','))
      }
      this.show = false
    }
  },
  mounted() {
    this.initValue(this.value)
  }
}
</script>