<template lang="pug">
div
  van-field(v-model='labelValue' readonly @click="picker=true" v-bind='$attrs'  placeholder='请选择报工工序')
  van-popup(v-model:show="picker" position="bottom" )
    van-search(v-model="searchkey" placeholder="请输入搜索关键词" shape="round")
    van-picker(:columns="currentOptions" @confirm="onPickerConfirm" @cancel="picker = false" show-toolbar)
</template>

<script>
export default {
  name: 'PickerSearchSelect',
  props: ['value', 'label', 'options'],
  data() {
    return {
      show : false,
      picker: false,
      searchkey: '',
      labelValue: "",
      currentOptions: [],
      originOptions: []
    }
  },
  watch: {
    options() {
      this.initOptions()
    },  
    searchkey(newV) {
      if (newV) {
        this.currentOptions = this.originOptions.filter(p => p.text.indexOf(newV) != -1)
      } else {
        this.currentOptions = [...this.originOptions]
      }
    }
  },
  methods: {
    initOptions() {
      this.originOptions = this.options ? this.options : []
      this.currentOptions = [...this.originOptions]
    },
   
    onPickerConfirm(obj) {
      // e.stopPropagation()
      this.labelValue = obj ? obj.text : ''
      this.picker = false
      const res = obj ? this.originOptions.find(p => p.text == obj) : ''
      this.$emit('callback', res)
    }
  },
  mounted() {
    this.initOptions()
  }
}
</script>