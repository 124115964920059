import path from 'path'
const requireComponent = require.context('./', true, /\.vue$/)

const importedComponets = requireComponent.keys().map(fileName => {
    const componentConfig = requireComponent(fileName)
    const ctrl = componentConfig.default || componentConfig
    // console.log(fileName)
    const ctrlFileName = path.basename(fileName).split('.')[0]
    // Vue.component(ctrl.name || ctrlFileName, ctrl)
    return {
        name: ctrl.name || ctrlFileName,
        component: ctrl
    }
})
const components = importedComponets.reduce((pr, current) => {
    Object.assign(pr, {
        [current.name]: current.component
    })
    return pr
}, {})
// console.log(components)


export default {
    components
}