<template lang="pug">
div
  van-field(:label="label" v-model="v")
    template(#button)
      | {{suffix}}
</template>

<script>
export default {
  name: 'SuffixField',
  props: ['value', 'label', 'suffix'],
  data() {
    return {
      v: ''
    }
  },
  watch: {
    value(newV) {
      this.v = newV
    },
    v(newV) {
      this.$emit('input', newV)
    }
  },
  mounted() {
    this.v = this.value
  }
}
</script>